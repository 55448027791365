import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { CreateVehiclesCheckedProps } from '../../../../../@types/types';

const CreateVehiclesChecked = ({ vehicleFormData, handleChange }: CreateVehiclesCheckedProps) => {

  return (
    <Box sx={{ml: 2}}>
      <FormControlLabel
        control={
          <Checkbox
            checked={vehicleFormData.status}
            onChange={handleChange}
            name="status"
          />
        }
        label="Em Estoque"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={vehicleFormData.acceptsExchange}
            onChange={handleChange}
            name="acceptsExchange"
          />
        }
        label="Aceita Troca"
      />
    </Box>
  );
};

export default CreateVehiclesChecked;