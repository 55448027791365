import { FooterContainer } from './styles/FooterComponet.styles';

interface FooterComponentProps {
  children?: React.ReactNode; 
}

const FooterComponent = ({ children }: FooterComponentProps) => {
  return <FooterContainer>{children}</FooterContainer>
};

export default FooterComponent;