import { Box, Button, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, FormLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useUpdateVehicle } from '../../../Hooks/useUpdateVehicle';
import { useParams } from 'react-router-dom';
import { FaRegEdit } from "react-icons/fa";
import ScreenHeader from '../../../Components/ScreenHeader/ScreenHeader';
import CurrencyMaskCustom from '../../../Components/Mask/PriceMask';
import ExtraItemsAutocomplete from '../RegisterVehicles/Components/VehicleForm/components/Inputs/InputCheckboxesTags/CreateVehicleCheckboxesTags';
import { brands, colors, displacements, types, years } from '../RegisterVehicles/Components/VehicleForm/data/vehicleData';
import FooterComponent from '../../../Layouts/PrivateLayouts/Components/Footer/Footer';


const UpdateVehicle = () => {
  const { id: vehicleId } = useParams<{ id: string }>();

  const defaultOnUpdateSuccess = () => {
    alert('Atualização realizada com sucesso.')
  }

  const { vehicle, loading, errors, handleChange, handleSubmit, handleItemChange } = useUpdateVehicle({
    vehicleId: vehicleId || '',
    onSubmitSuccess: defaultOnUpdateSuccess,
  });


  if (!vehicleId) {
    return <div>ID do veículo não encontrado!</div>;
  };

  if (loading) {
    return <CircularProgress />;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container
        sx={{
          mb: '7rem',
        }}
      >
        <ScreenHeader
          icon={<FaRegEdit />}
          title="Editar informações do veículo"
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: '1.1rem' }}>
          <TextField
            fullWidth
            id="outlined-text"
            type="text"
            variant="outlined"
            name='title'
            label='Título'
            value={vehicle.title || ''}
            onChange={(e) => handleChange('title', e.target.value)}
            error={!!errors.title}
            helperText={errors.title}
          />
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
              fullWidth
              id="outlined-text"
              variant="outlined"
              type="text"
              name="mileage"
              label="Quilometragem"
              value={vehicle.mileage || ''}
              onChange={(e) => handleChange('mileage', e.target.value)}
              error={!!errors.title}
              helperText={errors.title}
            />
            <TextField
              fullWidth
              id="outlined-text"
              variant="outlined"
              type="text"
              name="price"
              label="Valor do veículo"
              value={vehicle.price || ''}
              onChange={(e) => handleChange('price', e.target.value)}
              error={!!errors.title}
              helperText={errors.title}
              sx={{ display: 'flex', alignItems: 'center' }}
              slotProps={{
                input: {
                  inputComponent: CurrencyMaskCustom as any,
                }
              }}
            />
          </Box>
          {/*----------------->Inputs Select<--------------*/}
          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <FormControl fullWidth>
              <FormLabel sx={{ ml: 1 }}>
                Cilindrada
              </FormLabel>
              <Select
                id="displacement"
                name="displacement"
                value={vehicle.displacement || ''}
                onChange={(e) => handleChange('displacement', e.target.value)}
                error={!!errors.title}
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography variant="body2" color="var(--color-GreyText)">
                    Selecionar Cinlindrada
                  </Typography>
                </MenuItem>
                {displacements.map(cc => (
                  <MenuItem key={cc} value={cc}>
                    {cc}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel sx={{
                ml: 1
              }}>
                Marca
              </FormLabel>
              <Select
                id="brand"
                name="brand"
                value={vehicle.brand || ''}
                onChange={(e) => handleChange('brand', e.target.value)}
                error={!!errors.title}
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography variant="body2" color="var(--color-GreyText)">
                    Selecionar Marca
                  </Typography>
                </MenuItem>
                {brands.map(brand => (
                  <MenuItem key={brand} value={brand}>
                    {brand}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel required sx={{
                ml: 1
              }}>
                Ano
              </FormLabel>
              <Select
                id="year"
                name="year"
                value={vehicle.year}
                onChange={(e) => handleChange('year', e.target.value)}
                error={!!errors.title}
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography variant="body2" color="var(--color-GreyText)">
                    Selecionar Ano
                  </Typography>
                </MenuItem>
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{
            display: 'flex',
            gap: 1,
          }}>
            <FormControl fullWidth>
              <FormLabel required sx={{
                ml: 1
              }}>
                Categoria
              </FormLabel>
              <Select
                id="type"
                name="type"
                value={vehicle.type}
                onChange={(e) => handleChange('type', e.target.value)}
                required
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography variant="body2" color="var(--color-GreyText)">
                    Selecionar categoria
                  </Typography>
                </MenuItem>
                {types.map((type) => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel required sx={{
                ml: 1
              }}>
                Cor
              </FormLabel>
              <Select
                id="color"
                name="color"
                value={vehicle.color}
                onChange={(e) => handleChange('color', e.target.value)}
                required
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography variant="body2" color="var(--color-GreyText)">
                    Selecionar Cor
                  </Typography>
                </MenuItem>
                {colors.map((color) => (
                  <MenuItem key={color} value={color}>
                    {color}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* -----------------> Checked <------------------ */}
          <Box sx={{ ml: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={vehicle.status}
                  onChange={(e) => handleChange('status', e.target.value)}
                  name="status"
                />
              }
              label="Em Estoque"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={vehicle.acceptsExchange}
                  onChange={(e) => handleChange('acceptsExchange', e.target.value)}
                  name="acceptsExchange"
                />
              }
              label="Aceita Troca"
            />
          </Box>

          {/* ----------> Autocomplet <------------ */}

          <Box>
            <FormControl fullWidth>

              <ExtraItemsAutocomplete
                value={vehicle.extraIds || []}
                onChange={(newExtrasIds) => handleItemChange(newExtrasIds)}
              />
            </FormControl>
          </Box>


          {/* ---------------------> TextArea <------------------ */}

          <TextField
            label="Descrição do Veículo"
            variant="outlined"
            multiline
            rows={4}
            name="description"
            value={vehicle.description}
            onChange={(e) => handleChange('description', e.target.value)}
            required
            placeholder="Digite uma descrição detalhada do veículo"
            fullWidth
          />
        </Box>
      </Container>

      {/* --------------------> Footer <----------------------- */}

      <FooterComponent>
        <Typography variant="subtitle1" color="var(--color-GreyText)">
          Ao finalizar de alterar os dados, não esqueça de salvar!
        </Typography>
        <Button
          type='submit'
          disabled={loading}
          variant='contained'
        >
          {loading ? (
            <CircularProgress size={24} />

          ) : (
            <p>Salvar Alterações</p>
          )}
        </Button>
      </FooterComponent>
    </form>
  );
};

export default UpdateVehicle;