import { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { Box, Container, Paper, TableCell, TableRow } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import DeleteModal from '../Modals/DeleteModal/DeleteModal';
import EditModal from '../Modals/EditModal/EditModal';
import { useExtrasItems } from '../../../../../Hooks/useExtrasItems';
import { TableCustom } from './styles/TableExtrasItems.styles';

interface ExtraItem {
  id: string;
  name: string;
  description: string;
}

const TableExtrasItems = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ExtraItem | null>(null);
  const [modalType, setModalType] = useState<'edit' | 'delete' | null>(null);
  const { extrasItems, fetchExtrasItems } = useExtrasItems();

  useEffect(() => {
    fetchExtrasItems();
  }, []);

  const handleOpenModalDelete = (item: ExtraItem) => {
    setSelectedItem(item);
    setModalType('delete');
    setIsModalOpen(true);
  };

  const handleOpenModalEdit = (item: ExtraItem) => {
    setSelectedItem(item);
    setModalType('edit');
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
    setModalType(null);
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Nome', width: 216, },
    { field: 'description', headerName: 'Descrição', width: 216 },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 216,
      renderCell: (params: GridCellParams) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            gap: 3,
          }}
        >
          <Edit
            style={{ cursor: 'pointer' }}
            onClick={() => handleOpenModalEdit(params.row)}
          />
          <Delete
            style={{ cursor: 'pointer' }}
            onClick={() => handleOpenModalDelete(params.row)}
          />
        </Box>
      ),
    },
  ];

  const rows = extrasItems.map((item) => ({
    id: item.id,
    name: item.name,
    description: item.description,
  }));

  return (
    <Container>
      <Paper
        sx={{
          height: 400,
          width: '100%',
          mt: 4,
        }}
      >
        <TableCustom
          rows={rows}
          columns={columns}
          checkboxSelection
          sx={{
            border: 0,
            width: '100%',
          }}
        />
      </Paper>

      {modalType === 'edit' && (
        <EditModal visible={isModalOpen} onClose={handleCloseModal} item={selectedItem} />
      )}

      {modalType === 'delete' && (
        <DeleteModal visible={isModalOpen} onClose={handleCloseModal} item={selectedItem} />
      )}
    </Container>
  );
};


export default TableExtrasItems;
