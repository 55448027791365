import React from 'react';
import { Modal, Typography, Button } from '@mui/material';
import { CloseButton, Container, Content, Footer } from './Styles/Modal.styles';


interface ModalProps {
  visible: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

const CustomModal = ({ visible, onClose, title, children, footer }: ModalProps) => {
  return (
    <Modal open={visible} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Container>
        {/* Título */}
        {title && (
          <Typography 
          id="modal-title" 
          variant="h6" 
          component="h2" 
          className="modal-title" 
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: '1rem'
          }}
          >
            {title}
          </Typography>
        )}

        {/* Conteúdo */}
        <Content>{children}</Content>

        {/* Rodapé */}
        {footer && <Footer>{footer}</Footer>}

        {/* Botão Fechar */}
        <CloseButton>
          <Button 
          variant="contained" 
          color="error" 
          onClick={onClose}
          sx={{
            background: 'transparent',
            color: '#00AFEF',
            boxShadow: 'none',
            "&:hover": {
              boxShadow: 'none',
              textDecoration: 'underline'
            }
          }}
          >
            Fechar
          </Button>
        </CloseButton>
      </Container>
    </Modal>
  );
};

export default CustomModal;
