// Lista de cilindradas
export const displacements = [
  '50', '100', '110', '125', '150', '160', '190', '200', '250', '300', 
  '320', '350','400', '500', '600', '650', '700', '750', '800', '850', '900', 
  '950', 'Acima de 1000'
];


// Lista de marcas
export const brands = [
  "Honda", "Yamaha", "Suzuki", "Kawasaki", "BMW", "Ducati", 
  "Triumph", "Harley-Davidson", "KTM", "Royal Enfield", 
  "MV Agusta", "Aprilia", "Benelli", "Hero", "TVS", "Bajaj", 
  "Mahindra", "Shineray"
].sort();

// Gera anos de 2000 a 2024
export const years = Array.from({ length: 31 }, (_, i) => (2000 + i).toString());

// Lista de cores
export const colors = [
  'Verde', 'Laranja', 'Azul', 'Cinza', 'Preta', 'Branca', 'Vermelha', 'Dourada', 
];

export const types = [
  'Street', 'Naked', 'Scooter', 'Custom', 'Trail', 'Big Trail', 'Touring', 'Sport'
].sort();


export const extraItems = [
  'Escape esportivo', 'Farol de neblina'
]
