import React from 'react';
import { TextField, Box } from '@mui/material';

interface SearchListVehicleProps {
  searchQuery: string;
  onSearch: (query: string) => void;
}

const SearchListVehicle: React.FC<SearchListVehicleProps> = ({ searchQuery, onSearch }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <Box mb={3}>
      <TextField
        label="Pesquisar Veículo"
        variant="outlined"
        value={searchQuery}
        onChange={handleInputChange}
        fullWidth
      />
    </Box>
  );
};

export default SearchListVehicle;
