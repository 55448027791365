import { useState, useEffect } from 'react';

import { apiClient } from '../Services/apiClient';

interface UpdateVehicleRequest {
  id: string;
  title: string;
  description: string;
  mileage: string;
  displacement: string;
  acceptsExchange: boolean;
  price: number;
  year?: string;
  color?: string;
  status?: boolean;
  brand?: string;
  type?: string;
  extraIds: string[];
  vehicleExtras?: { extrasItem_id: string }[];
};

interface UseUpdateVehicleProps {
  vehicleId: string;
  onSubmitSuccess: () => void;
};

export const useUpdateVehicle = ({ vehicleId, onSubmitSuccess }: UseUpdateVehicleProps) => {
  const [vehicle, setVehicle] = useState<Partial<UpdateVehicleRequest>>({
    displacement: '',
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    const fetchVehicle = async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(`vehicles/${vehicleId}`);
        const data = response.data;

        const extraIds = data.vehicleExtras.map((extra: any) => extra.extrasItem_id)

        console.log(data)
        setVehicle({
          ...data,
          extraIds: extraIds,
        });
      } catch (err) {
        console.error('Error fetching Vehicle', err)
      } finally {
        setLoading(false);
      }
    };

    fetchVehicle();
  }, [vehicleId]);

  const handleChange = (field: keyof UpdateVehicleRequest, value: any) => {
    setVehicle((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleItemChange = (selectedIds: string[] | null) => {
    setVehicle((prev) => ({
      ...prev,
      extraIds: selectedIds || [], 
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setErrors({});
      await apiClient.put(`vehicle/updateVehicle/${vehicleId}`, vehicle);
      onSubmitSuccess();
    } catch (err: any) {
      console.error(err);
      setErrors(err.response?.data?.errors || {});
    } finally {
      setLoading(false);
    }
  };

  return {
    vehicle,
    loading,
    errors,
    handleChange,
    handleSubmit,
    handleItemChange,
  };
};