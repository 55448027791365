import { Box, Button, CircularProgress, Container, FormControl, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormExtraItems } from './Styles/FormCreateExtrasItems';
import { IoAddSharp } from "react-icons/io5";
import { useExtrasItems } from '../../../../../Hooks/useExtrasItems';
import { toast } from 'react-toastify';
import { IoIosAddCircleOutline } from 'react-icons/io';


const FormCreateExtrasItems = () => {
  const { addExtraItem, loading } = useExtrasItems();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!name || !description) {
      toast.warning('Preencha todos os campos.');
      return;
    }

    await addExtraItem(name, description);
    setName('');
    setDescription('');
  };

  return (
    <Container>
      <Box
        sx={{
          backgroundColor: 'var(--color-BluePrimary)',
          mb: 1,
        }}
      >
        <Typography 
         variant="subtitle1"
         sx={{
           display: 'flex',
           alignItems: 'center',
           gap: 0.5,
           color: '#FFF',
           pl: 2,
           pt: 2,
           fontSize: '1.7em'
         }}>
         <IoIosAddCircleOutline /> Adicionar Itens Extras
        </Typography>
      </Box>
      <FormExtraItems onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Item extras"
            placeholder="Descreva o item"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Descrição"
            placeholder="Crie uma descrição"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </FormControl>
        <Button type='submit' variant='contained' color='primary' disabled={loading}>
          {loading ? (
            <CircularProgress size={32} color="primary" />
          ) : (
            <IoAddSharp size={32} />
          )}
        </Button>
      </FormExtraItems>
    </Container>
  );
};

export default FormCreateExtrasItems;