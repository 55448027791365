import React, { createContext, useContext, useState, ReactNode } from 'react';

import { apiClient } from '../Services/apiClient';
import { toast } from 'react-toastify';

// Definindo o tipo dos itens extras
interface ExtraItem {
  id: string;
  name: string;
  description: string;
}

// Definindo o tipo do contexto
interface ExtrasItemsContextProps {
  extrasItems: ExtraItem[];
  loading: boolean;
  fetchExtrasItems: () => Promise<void>;
  addExtraItem: (name: string, description: string) => Promise<void>;
  deleteExtraItem: (id: string) => Promise<void>;
  updateExtraItem: (id: string, data: { name: string; description: string }) => Promise<void>; 
}

const ExtrasItemsContext = createContext<ExtrasItemsContextProps | undefined>(undefined);

export const ExtrasItemsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [extrasItems, setExtrasItems] = useState<ExtraItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Buscar os itens extras
  const fetchExtrasItems = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get('/extrasItems');
      setExtrasItems(response.data);
    } catch (error) {
      console.error('Erro ao buscar os itens extras:', error);
      toast.error('Erro ao buscar os itens extras.');
    } finally {
      setLoading(false);
    }
  };

  // Adicionar um novo item extra
  const addExtraItem = async (name: string, description: string): Promise<void> => {
    if (!name || !description) {
      toast.warning('Preencha todos os campos.');
      return;
    }
    setLoading(true);
    try {
      const response = await apiClient.post('/create-extrasItems', { name, description });
      setExtrasItems((prev) => [...prev, response.data]);
      toast.success('Item cadastrado');
    } catch (error) {
      console.error('Erro ao adicionar item extra:', error);
      toast.error('Erro ao adicionar item extra.');
    } finally {
      setLoading(false);
    }
  };

  //Deletar itens
  const deleteExtraItem = async (id: string) => {
    setLoading(true);
    try {
      await apiClient.delete(`/extrasItems/${id}`);
      setExtrasItems((prev) => prev.filter((item) => item.id !== id));
      toast.success('Item excluido!')
    } catch (error: any) {
      console.error('Erro ao excluir item extra:', error);
      if (error.response?.status === 500) {
        toast.error('Erro ao excluir item extra, item vinculado!');
      }
    } finally {
      setLoading(false);
    }
  };

  //Editar itens
  const updateExtraItem = async (id: string, data: { name: string; description: string }): Promise<void> => {
    if (!data.name || !data.description) {
      toast.warning('Preencha todos os campos.');
      return;
    }
    setLoading(true);
    try {
      await apiClient.put(`/extrasItems/${id}`, data);
      setExtrasItems((prev) =>
        prev.map((item) =>
          item.id === id ? { ...item, ...data } : item
        )
      );
      toast.success('Item atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar item extra:', error);
      toast.error('Erro ao atualizar item extra.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ExtrasItemsContext.Provider value={{ extrasItems, loading, fetchExtrasItems, addExtraItem, deleteExtraItem, updateExtraItem }}>
      {children}
    </ExtrasItemsContext.Provider>
  );
};

// Hook para consumir o contexto
export const useExtrasItems = () => {
  const context = useContext(ExtrasItemsContext);
  if (!context) {
    throw new Error('useExtrasItems deve ser usado dentro de um ExtrasItemsProvider');
  }
  return context;
};
