import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import AuthProvider, { useAuth } from '../Context/Auth/AuthContext';
import SignIn from '../Pages/SignIn';
import { ResetPasswordFlow } from '../Pages/ResetPassword/ResetPage';
import CreateVehicle from '../Pages/Vehicles/RegisterVehicles';
import Dashboard from '../Pages/Dashboard';
import RegisterCompany from '../Pages/Company/RegisterCompany';
import AllCompanies from '../Pages/Company/AllCompanies/AllCompanies';
import CompanyDetails from '../Pages/Company/CompanyDetail/CompanyDetail';
import VehiclesPage from '../Pages/Vehicles/ListVehicles';
import DetailVehicle from '../Pages/Vehicles/DetailVehicle';
import PrivateLayout from '../Layouts/PrivateLayouts';
import NotFound from '../Pages/NotFound/NotFound';
import Loading from '../Pages/Loading';
import ExtraItemsPage from '../Pages/Tools/ExtrasItems/ExtrasItensPage';
import UpdateVehicle from '../Pages/Vehicles/UpdateVehicle';


export default function AppRoutes() {
  

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<PublicRoute />} >
            <Route path='/SignIn' element={<SignIn />} />
            <Route path='/resetLogin' element={<ResetPasswordFlow />} />
          </Route>
          
          <Route element={<PrivateRoute />}>
            <Route
              element={
                <PrivateLayout>
                  <Outlet/>
                </PrivateLayout>
              }
            >
              <Route path='/' element={<Loading/>}/>
              <Route path='/cadatrasVeiculos' element={<CreateVehicle />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/registerCompany' element={<RegisterCompany />} />
              <Route path='/allcompanies' element={<AllCompanies />} />
              <Route path='/company/:id' element={<CompanyDetails />} />
              <Route path='/vehicleform' element={<CreateVehicle />} />
              <Route path="/vehicles/company" element={<VehiclesPage />} />
              <Route path='/vehicles/:id' element={<DetailVehicle />} />
              <Route path='/createitem' element={<ExtraItemsPage />} />
              <Route path='/vehicle/updateVehicle/:id' element={<UpdateVehicle />} />
            </Route>
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter >
  )
}