import { Box, TextField } from '@mui/material';
import { CreateVehicleInputTextProps } from '../../../../../@types/types';
import CurrencyMaskCustom from '../../../../../../../../Components/Mask/PriceMask';

const CreateVehicleInputText = ({ vehicleFormData, handleChange }: CreateVehicleInputTextProps) => {
  return (
    <>
      <TextField
        fullWidth
        id="outlined-text"
        type="text"
        variant="outlined"
        name="title"
        label="Título"
        value={vehicleFormData.title}
        onChange={handleChange}
        required
        placeholder="Ex: (Marca, Modelo, Cilíndrada e Ano)"
      />
      <Box sx={{display: 'flex',  gap: 1}}>
        <TextField
          fullWidth
          id="outlined-text"
          variant="outlined"
          type="text"
          name="mileage"
          value={vehicleFormData.mileage || ''}
          onChange={handleChange}
          required
          label="Quilometragem"
          placeholder="Ex: 15000"
        />
        <TextField
          fullWidth
          id="outlined-text"
          variant="outlined"
          type="text"
          name="price"
          value={vehicleFormData.price}
          onChange={handleChange}
          required
          label="Valor do veículo"
          placeholder="Digite o preço do veículo"

          slotProps={{
            input: {
              inputComponent: CurrencyMaskCustom as any,
            }
          }}
        />
      </Box>
    </>
  );
};

export default CreateVehicleInputText;
