import { Box, FormControl, FormLabel, MenuItem, Select, Typography } from '@mui/material';
import { CreateVehicleInputSelectProps} from '../../../../../@types/types';
import { brands, colors, displacements, types, years } from '../../../data/vehicleData';

const CreateVehicleInputSelect = ({vehicleFormData, handleChange}: CreateVehicleInputSelectProps) => {
  
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        <FormControl fullWidth >
          <FormLabel required sx={{
            ml: 1
          }}>
            Cilindrada
          </FormLabel>
          <Select
            id="displacement"
            name="displacement"
            value={vehicleFormData.displacement}
            onChange={handleChange}
            required
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  overflowY: 'auto',
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              <Typography variant="body2" color="var(--color-GreyText)">
                Selecionar Cinlindrada
              </Typography>
            </MenuItem>
            {displacements.map(cc => (
              <MenuItem key={cc} value={cc}>
                {cc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required sx={{
            ml: 1
          }}>
            Marca
          </FormLabel>
          <Select
            id="brand"
            name="brand"
            value={vehicleFormData.brand}
            onChange={handleChange}
            required
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  overflowY: 'auto',
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              <Typography variant="body2" color="var(--color-GreyText)">
                Selecionar Marca
              </Typography>
            </MenuItem>
            {brands.map(brand => (
              <MenuItem key={brand} value={brand}>
                {brand}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required sx={{
            ml: 1
          }}>
            Ano
          </FormLabel>
          <Select
            id="year"
            name="year"
            value={vehicleFormData.year}
            onChange={handleChange}
            required
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  overflowY: 'auto',
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              <Typography variant="body2" color="var(--color-GreyText)">
                Selecionar Ano
              </Typography>
            </MenuItem>
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{
        display: 'flex',
        gap: 1,
      }}>
        <FormControl fullWidth>
          <FormLabel required sx={{
            ml: 1
          }}>
            Categoria
          </FormLabel>
          <Select
            id="type"
            name="type"
            value={vehicleFormData.type}
            onChange={handleChange}
            required
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  overflowY: 'auto',
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              <Typography variant="body2" color="var(--color-GreyText)">
                Selecionar categoria
              </Typography>
            </MenuItem>
            {types.map((type) => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required sx={{
            ml: 1
          }}>
            Cor
          </FormLabel>
          <Select
            id="color"
            name="color"
            value={vehicleFormData.color}
            onChange={handleChange}
            required
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  overflowY: 'auto',
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              <Typography variant="body2" color="var(--color-GreyText)">
                Selecionar Cor
              </Typography>
            </MenuItem>
            {colors.map((color) => (
              <MenuItem key={color} value={color}>
                {color}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default CreateVehicleInputSelect;