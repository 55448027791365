import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiClient } from '../../../Services/apiClient';
import { 
  ContainerImagemTitle,
  ContainerVehicleDetails,
  ContentVehicleDetails,
  DetailContainer, 
  InfoContainer, 
  VehicleTitle 
} from './styles/DetailVehicle.styles'; // Importar os estilos
import ImageViewer from './components/ImageViewer/ImageViewer';
import { FaEdit, FaTrash } from 'react-icons/fa';

import { CiCircleList } from "react-icons/ci";

const DetailVehicle = () => {
  const { id } = useParams<{ id: string }>();
  const [vehicle, setVehicle] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVehicleDetails = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await apiClient.get(`/vehicles/${id}`);
        setVehicle(response.data);
      } catch (err) {
        console.error('Erro ao buscar detalhes do veículo:', err);
        const errorMessage = (err as Error).message;
        setError(`Erro: ${errorMessage}`);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicleDetails();
  }, [id]);

  // Função para navegar de volta para a lista de veículos da empresa
  const handleReturnList = () => {
    navigate('/vehicles/company'); // Navegar para a página '/vehicles/company'
  };

  if (loading) return <div role="alert">Carregando detalhes do veículo...</div>;
  if (error) return <div role="alert">{error}</div>;
  if (!vehicle) return <div role="alert">Veículo não encontrado.</div>;

  return (
    <ContainerVehicleDetails>
      <DetailContainer>
        <ContentVehicleDetails>
          <ContainerImagemTitle>
            <VehicleTitle>{vehicle.title}</VehicleTitle>
            {vehicle.images && vehicle.images.length > 0 && (
              <ImageViewer images={vehicle.images.map((img: { id: number; image_url: string }) => ({
                id: img.id,
                url: img.image_url,
                isMain: img.id === vehicle.images[0].id // Defina a primeira imagem como a principal
              }))} />
            )}
          </ContainerImagemTitle>
          <InfoContainer>
            <h2>Dados do veiculo</h2>
            <div>
              <label>Ano:</label>
              <p>{vehicle.year}</p>
            </div>
            <div>
              <label>Quilometragem:</label>
              <p>{vehicle.mileage} km</p>
            </div>
            <div>
              <label>Preço:</label>
              <p>R$ {vehicle.price.toFixed(2)}</p>
            </div>
            <div>
              <label>Cor:</label>
              <p>{vehicle.color}</p>
            </div>
            <div>
              <label>Cilindrada:</label>
              <p>{vehicle.displacement} cc</p>
            </div>
            <div>
              <label>Descrição:</label>
              <p>{vehicle.description}</p>
            </div>
            <div className="button-container">
              <button>
                Editar
                <FaEdit />
              </button>
              <button>
                Excluir
                <FaTrash />
              </button>
              {/* Botão para voltar para a lista de veículos */}
              <button onClick={handleReturnList}>
                Voltar para lista de veículos
                <CiCircleList />
              </button>
            </div>
          </InfoContainer>
        </ContentVehicleDetails>
      </DetailContainer>
    </ContainerVehicleDetails>
  );
};

export default DetailVehicle;
