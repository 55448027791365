import { TextField } from '@mui/material';
import { CreateVehiclesTextarea } from '../../../../../@types/types';

const CreateVehicleTextArea = ({ vehicleFormData, handleChange }: CreateVehiclesTextarea) => {
  return (
    <TextField
      label="Descrição do Veículo"
      variant="outlined" // Mantém a consistência com outros inputs
      multiline
      rows={4} // Define o número de linhas exibidas
      name="description"
      value={vehicleFormData.description}
      onChange={handleChange}
      required
      placeholder="Digite uma descrição detalhada do veículo"
      fullWidth // Usa a largura total do container
    />
  );
};

export default CreateVehicleTextArea;