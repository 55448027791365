import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import { VscTrash } from "react-icons/vsc";
import CustomModal from '../../../../../Layouts/PrivateLayouts/Components/Modal/Modal';
import { useListVehicles } from '../../../../../Hooks/useListVehicles';

interface DeleteModalVehicleProps {
  visible: boolean;
  onClose: () => void;
  vehicle?: { id: string; title: string; } | null;
}

const DeleteModalVehicle = ({ visible, onClose, vehicle }: DeleteModalVehicleProps) => {

  const [selectedVehicle, setSelectedVehicle] = useState(vehicle);
  const { deleteVehicle, loading, fetchVehicles } = useListVehicles();

  useEffect(() => {
    fetchVehicles();
  }, []); 

  const handleDelete = async () => {
    if (selectedVehicle?.id) {
      await deleteVehicle(selectedVehicle.id);
      onClose();
    }
  };

  useEffect(() => {
    setSelectedVehicle(vehicle);
  }, [vehicle]);


  return (
    <CustomModal
      visible={visible}
      onClose={onClose}
      title={"Deseja realmente excluir esse veículo?"}
      footer={
        <Button
          variant="contained"
          color="error"
          onClick={handleDelete}
          disabled={loading}
        >
          {loading ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1,}}>
              Excluindo <CircularProgress size={22} />
            </Box>  
          ) : (
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <VscTrash size={20} />
              Excluir veículo
            </Box>
          )}
        </Button>
      }
    >

      <Typography id="modal-description" variant="body1" 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        fontSize: '1.2em',
        color: 'var(--color-BluePrimary)'
        }}>
        <Box>
          { selectedVehicle?.title }
        </Box>
      </Typography>
    </CustomModal>
  );
};

export default DeleteModalVehicle;