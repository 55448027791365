import { IoIosAddCircleOutline } from "react-icons/io";

import { useState } from 'react';
import { useVehicleForm } from '../../../../../Hooks/useVehicleRegisterForm';

import CreateVehicleInputText from './components/Inputs/InputText/CreateVehicleInputText';
import CreateVehicleInputSelect from './components/Inputs/InputSelect/CreateVehicleInputSelect';
import CreateVehiclesChecked from './components/Inputs/Checked/CreateVehicleChecked';
import type { VehicleFormProps } from '../../@types/types';
import CreateVehicleTextArea from './components/Inputs/InputTextArea/CreateVehicleTextArea';
import ExtraItemsAutocomplete from './components/Inputs/InputCheckboxesTags/CreateVehicleCheckboxesTags';
import FooterComponent from '../../../../../Layouts/PrivateLayouts/Components/Footer/Footer';
import Typography from '@mui/material/Typography'
import { Box, Button, CircularProgress, Container, Paper } from '@mui/material';


const VehicleForm = ({ onSubmit }: VehicleFormProps) => {

  const { vehicleFormData, handleChange, handleSubmit, handleItemChange, loading } = useVehicleForm(onSubmit);


  return (
    <form onSubmit={handleSubmit}>
      <Container
        sx={{
          mb: '7rem',
        }}
      >

        <Box
          sx={{
            backgroundColor: 'var(--color-BluePrimary)',
            mb: 1,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              color: '#FFF',
              pl: 2,
              pt: 2,
              fontSize: '1.7em'
            }}>
            <IoIosAddCircleOutline /> Cadastrar veículo
          </Typography>
        </Box>


        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: '1.1rem' }}>
          <CreateVehicleInputText
            vehicleFormData={vehicleFormData}
            handleChange={handleChange}
          />

          <CreateVehicleInputSelect
            vehicleFormData={vehicleFormData}
            handleChange={handleChange}
          />

          <CreateVehiclesChecked
            vehicleFormData={vehicleFormData}
            handleChange={handleChange}
          />

          <ExtraItemsAutocomplete
            value={vehicleFormData.extraIds}
            onChange={handleItemChange}
          />

          <CreateVehicleTextArea
            vehicleFormData={vehicleFormData}
            handleChange={handleChange}
          />
        </Box>


      </Container>

      <FooterComponent>
        <Typography variant="subtitle1" color="var(--color-GreyText)">
          Finalizou de inserir as informações do veículo? Agora falta pouco, adicione as imagens.
        </Typography>
        <Button
          type='submit'
          disabled={loading}
          variant='contained'
        >
          {loading ? (
            <CircularProgress size={24} />

          ) : (
            <p>Próximo</p>
          )}
        </Button>
      </FooterComponent>
    </form>
  );
};

export default VehicleForm;
