export const MenuLinksData = [
  {
    buttonLabel: 'Vendas',
    menuItems: [
      {label: 'Comissões', action: '#'},
      {label: 'Registrar uma Venda', action: '#'},
    ]
  },
  {
    buttonLabel: 'Veículos',
    menuItems: [
      {label: 'Estoque', action: '/vehicles/company'},
      {label: 'Cadastrar', action: '/cadatrasVeiculos'},
      {label: 'Documentação', action: '/createitem'},
    ]
  },
  {
    buttonLabel: 'Relátorios',
    menuItems: [
      {label: 'Vendas', action: '#'},
      {label: 'Gastos', action: '#'},
      {label: 'Comissões', action: '#'},
      {label: 'Manutenção', action: '#'},
      {label: 'Consignação', action: '#'},
      {label: 'Estoque', action: '#'},
    ]
  },
  {
    buttonLabel: 'Transferências',
    menuItems: [
      {label: 'Visualizar processo', action: '#'},
      {label: 'Adicionar processo', action: '#'},
    ]
  },
  {
    buttonLabel: 'Consignados',
    menuItems: [
      {label: 'Entrada de veículo', action: '#'},
      {label: 'Saída de Veículo', action: '#'},
      {label: 'Lista Consignados', action: '#'},
    ]
  },
  {
    buttonLabel: 'Marketing',
    menuItems: [
      {label: 'Relátorios', action: '#'},
      {label: 'Leads', action: '#'},
      {label: 'Publico alvo', action: '#'},
      {label: 'Produtos', action: '#'},
    ]
  },
];
