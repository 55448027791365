import { Box, Button, CircularProgress, Typography } from '@mui/material'
import CustomModal from '../../../../../../Layouts/PrivateLayouts/Components/Modal/Modal';
import { useEffect, useState } from 'react';
import { TitleItem } from './styles/DeleteModal.styles';
import { VscTrash } from "react-icons/vsc";
import { useExtrasItems } from '../../../../../../Hooks/useExtrasItems';

interface DeleteModalProps {
  visible: boolean;
  onClose: () => void;
  item?: { id: string; name: string; description: string } | null;
}

const DeleteModal = ({ visible, onClose, item }: DeleteModalProps) => {

  const [selectedItem, setSelectedItem] = useState(item);
  const { deleteExtraItem, loading } = useExtrasItems();

  const handleDelete = async () => {
    if (selectedItem?.id) {
      await deleteExtraItem(selectedItem.id);
      onClose();
    }
  };

  useEffect(() => {
    setSelectedItem(item);
  }, [item]);


  return (
    <CustomModal
      visible={visible}
      onClose={onClose}
      title={`Deseja deletar o item: ${selectedItem?.name || ''}`}
      footer={
        <Button
          variant="contained"
          color="error"
          onClick={handleDelete}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <VscTrash size={20} />
              Excluir
            </Box>
          )}
        </Button>
      }
    >

      <Typography id="modal-description" variant="body1">
        <Box>
          <TitleItem>
            Nome:
          </TitleItem>
          <div>
            {selectedItem?.name || ''}
          </div>
        </Box>
        <Box>
          <TitleItem>
            Descrição:
          </TitleItem>
          <div>
            {selectedItem?.description || ''}
          </div>
        </Box>
      </Typography>
    </CustomModal>
  );
};

export default DeleteModal;