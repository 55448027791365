import { GridColDef, GridRowsProp, DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, IconButton, Typography } from '@mui/material';
import { useListVehicles } from '../../../Hooks/useListVehicles';
import DeleteModalVehicles from './Modal/DeleteModal/DeleteModalVehicles';
import { useEffect, useState } from 'react';
import { ContainerDataGrid, ContainerListVehicles } from './styles/ListVehicles.styles';

import { CiViewList } from "react-icons/ci";
import { VscTrash } from 'react-icons/vsc';
import { LiaEditSolid } from "react-icons/lia";
import { SlEye } from "react-icons/sl";
import SearchListVehicle from './SearchListVehicle/SearchListVehicle';

interface DeleteVehicleModalProps {
  id: string;
  title: string;
}

interface Vehicle {
  id: string;
  title: string;
  year: string;
  mileage: string;
  price: number;
  color: string;
  displacement: string;
  brand: string;
}

const VehiclesPage = () => {
  const { vehicles, handleViewDetails, handleEdit, fetchVehicles, loading } = useListVehicles();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<DeleteVehicleModalProps | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredVehicles, setFilteredVehicles] = useState<Vehicle[]>([]);

  useEffect(() => {
    fetchVehicles();
  }, []);

  useEffect(() => {

    if (Array.isArray(vehicles)) {
      const filtered = vehicles.filter((vehicle: Vehicle) =>
        vehicle.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredVehicles(filtered);
    }
  }, [vehicles, searchQuery]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleOpenModal = (vehicle: DeleteVehicleModalProps) => {
    setSelectedVehicle(vehicle);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setSelectedVehicle(null);
    setModalVisible(false);
    fetchVehicles();
  };

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Título', width: 200 },
    { field: 'brand', headerName: 'Marca', width: 120 },
    { field: 'year', headerName: 'Ano', width: 100 },
    { field: 'mileage', headerName: 'Quilometragem (km)', width: 180 },
    {
      field: 'price',
      headerName: 'Preço (R$)',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value as number;
        if (!value) return 'R$ 0,00';

        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(value);
      },
    },
    { field: 'color', headerName: 'Cor', width: 120 },
    { field: 'displacement', headerName: 'Cilindrada (cc)', width: 180 },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', mt: 1 }}>
          <IconButton onClick={() => handleViewDetails(params.row.id)}>
            <SlEye color='var(--color-BluePrimary)' size={22} />
          </IconButton>
          <IconButton onClick={() => handleEdit(params.row.id)}>
            <LiaEditSolid color='var(--color-BluePrimary)' size={22} />
          </IconButton>
          <IconButton onClick={() => handleOpenModal(params.row)}>
            <VscTrash color='var(--color-BluePrimary)' size={22} />
          </IconButton>
        </Box>
      ),
    },
  ];

  const rows: GridRowsProp = filteredVehicles.map((vehicle) => ({
    id: vehicle.id,
    title: vehicle.title,
    year: vehicle.year,
    mileage: vehicle.mileage,
    price: vehicle.price,
    color: vehicle.color,
    displacement: vehicle.displacement,
    brand: vehicle.brand,
  }));

  return (
    <ContainerListVehicles>
      <Box
        sx={{
          backgroundColor: 'var(--color-BluePrimary)',
          mb: 1,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            color: '#FFF',
            pl: 2,
            pt: 2,
            fontSize: '1.7em'
          }}
        >
          <CiViewList /> Lista de veículos
        </Typography>
      </Box>
      <Box sx={{ mt: 2}}>
        <SearchListVehicle searchQuery={searchQuery} onSearch={handleSearch} />
      </Box>
      <ContainerDataGrid>
        <DataGrid
          loading={loading}
          rows={rows}
          columns={columns}
          autoPageSize
          pageSizeOptions={[10, 20, 30]}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        />
      </ContainerDataGrid>

      <DeleteModalVehicles
        visible={modalVisible}
        onClose={handleCloseModal}
        vehicle={selectedVehicle}
      />
    </ContainerListVehicles>
  );
};

export default VehiclesPage;
