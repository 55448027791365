import { Box, Typography } from '@mui/material';

interface ScreenHeaderProps {
  icon: React.ReactNode;
  title: string;
};

const ScreenHeader = ({ icon, title }: ScreenHeaderProps) => {
  return (
    <Box
      sx={{
        backgroundColor: 'var(--color-BluePrimary)',
        mb: 1,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          color: '#fff',
          pl: 2,
          pt: 2,
          fontSize: '1.7em',
        }}
      >
        {icon} {title}
      </Typography>
    </Box>
  );
};

export default ScreenHeader;