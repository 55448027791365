import { useEffect, useState } from 'react';
import { useExtrasItems } from '../../../../../../Hooks/useExtrasItems';
import CustomModal from '../../../../../../Layouts/PrivateLayouts/Components/Modal/Modal';
import { Box, Button, CircularProgress, TextField } from '@mui/material';

interface EditModalProps {
  visible: boolean;
  onClose: () => void;
  item?: { id: string; name: string; description: string } | null;
}


const EditModal = ({ visible, onClose, item }: EditModalProps) => {

  const { updateExtraItem, loading } = useExtrasItems();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    if (item) {
      setFormData({
        name: item.name || '',
        description: item.description || '',
      });
    }
  }, [item]);

  // Atualiza o estado local conforme os inputs mudam
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Lida com a atualização do item
  const handleSave = async () => {
    if (item?.id) {
      await updateExtraItem(item.id, formData);
      onClose();
    }
  };

  return (
    <CustomModal
      visible={visible}
      onClose={onClose}
      title={`Editar item: ${item?.name || ''}`}
      footer={
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            'Salvar'
          )}
        </Button>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Nome"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="Descrição"
          name="description"
          value={formData.description}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          multiline
          rows={3}
        />
      </Box>
    </CustomModal>
  )
}

export default EditModal