import { ChangeEvent, useState } from 'react';
import { VehicleFormData } from '../Pages/Vehicles/RegisterVehicles/@types/types';
import { SelectChangeEvent } from '@mui/material';

type CustomEvent = {
  name: string;
  value: any; // Permite diferentes tipos, incluindo arrays
};

export const useVehicleForm = (onSubmit: (data: VehicleFormData) => void) => {
  const [vehicleFormData, setVehicleFormData] = useState<VehicleFormData>({
    title: '',
    description: '',
    mileage: '',
    displacement: '',
    acceptsExchange: false,
    price: 0,
    year: '',
    color: '',
    status: false,
    brand: '',
    type: '',
    extraIds: [], // Inicializa como array vazio
  });

  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | SelectChangeEvent | CustomEvent
  ) => {
    if ('name' in e && 'value' in e) {
      const { name, value } = e;

      if (name === 'extraIds') {
        // Atualiza diretamente o estado de extraIds
        setVehicleFormData((prevData) => ({
          ...prevData,
          extraIds: value,
        }));
        return;
      }
    }

    if ('target' in e) {
      const { name, value, type } = e.target as HTMLInputElement & { value: string | number };

      if (!name) {
        console.error("O campo 'name' não está definido no evento.");
        return;
      }

      if (type === 'checkbox') {
        const newValue = (e.target as HTMLInputElement).checked;
        setVehicleFormData((prevData) => ({
          ...prevData,
          [name]: newValue,
        }));
      } else if (name === 'price') {
        const numericValue = parseFloat(value.toString().replace(/[^\d.]/g, '')) || 0;
        setVehicleFormData((prevData) => ({
          ...prevData,
          [name]: numericValue,
        }));
      } else {
        setVehicleFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleItemChange = (selectedIds: string[] | null) => {
    // Atualiza os IDs de itens extras
    setVehicleFormData((prevData) => ({
      ...prevData,
      extraIds: selectedIds || [], // Garante que seja sempre um array
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Dados antes de enviar:', vehicleFormData);
    console.log('extraIds:', vehicleFormData.extraIds);
    try {
      setLoading(true);
      await onSubmit(vehicleFormData); // Envia o formulário com os dados atualizados
      setLoading(false);
    } catch (error) {
      console.error('Erro ao enviar o formulário:', error);
      setLoading(false);
    }
  };

  return {
    vehicleFormData,
    handleChange,
    handleSubmit,
    handleItemChange,
    setVehicleFormData,
    loading,
  };
};
