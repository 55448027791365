import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { apiClient } from '../../../../../../../../Services/apiClient';

interface ExtraItem {
  id: string; 
  name: string;
  description: string;
}

interface ExtraItemsAutocompleteProps {
  value: string[];
  onChange: (value: string[] | null) => void;
}

const ExtraItemsAutocomplete = ({ onChange, value }: ExtraItemsAutocompleteProps) => {
  const [ extraItemsOptions, setExtraItemsOptions ] = useState<ExtraItem[]>([]);
  const [ isLoading, setIsLoading ] = useState(false);

  const fetchExtraItemsFindOne = async () => {
    try {
      setIsLoading(true);
      const response = await apiClient.get(`/extrasItems`);
      const data: ExtraItem[] = response.data;
      setExtraItemsOptions(data);
    } catch (error) {
      console.error('Erro ao buscar itens extras:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchExtraItemsFindOne();
  }, []);

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={extraItemsOptions}
      getOptionLabel={(option) => option.name} // O label agora é 'name'
      isOptionEqualToValue={(option, value) => option.id === value.id} // Comparando objetos pelo id
      value={extraItemsOptions.filter((item) => value.includes(item.id))}
      loading={isLoading}
      onChange={(_, newValue) => {
        const selectedIds = newValue.map((item) => item.id); 
        onChange(selectedIds.length > 0 ? selectedIds : null);
      }}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          label="Selecione um item extra"
          placeholder="Itens extras"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ExtraItemsAutocomplete;
