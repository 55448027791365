import { ExtrasItemsProvider } from '../../../Hooks/useExtrasItems';
import FormCreateExtrasItems from './Component/FormCreateExtrasItems/FormCreateExtrasItems';
import TableExtrasItems from './Component/TableExtrasItems/TableExtrasItems';

export default function ExtraItemsPage() {
  return (
    <>
      <ExtrasItemsProvider>
        <FormCreateExtrasItems />
        <TableExtrasItems />
      </ExtrasItemsProvider>
    </>
  )
}