import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

export const TableCustom = styled(DataGrid)`
   & .MuiDataGrid-columnHeader {
    color: var(--color-BluePrimary); 
    font-weight: bold;
  }

  & .MuiDataGrid-cell {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  & .MuiDataGrid-columnSeparator {
    display: none;
  }

`