import styled from 'styled-components';

// Container principal do modal
export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 35rem;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 8px 32px 0 rgba(10, 10, 10, 0.7);
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
`;

// Conteúdo do modal
export const Content = styled.div`
  margin-bottom: 2rem;
`;

// Rodapé do modal
export const Footer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

// Botão de fechar
export const CloseButton = styled.div`
  text-align: right;
  margin-top: 2rem;
`;
