import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';  // Corrigido para importar corretamente
import { apiClient } from '../Services/apiClient';
import { toast } from 'react-toastify';

interface ListVehiclesProps {
  id: string;  
  title: string;
  year: string;
  mileage: string;
  price: number;
  color: string;
  displacement: string;
  brand: string;
  [key: string]: any;
}

interface UseListVehiclesReturn {
  vehicles: ListVehiclesProps[];
  loading: boolean;
  error: string | null;
  fetchVehicles: () => Promise<void>;
  deleteVehicle: (id: string) => Promise<void>;
  handleViewDetails: (id: string) => void;
  handleEdit: (id: string) => void;
}

export const useListVehicles = (): UseListVehiclesReturn => {
  const [vehicles, setVehicles] = useState<ListVehiclesProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const fetchVehicles = async () => {
    setLoading(true);
    setError(null);

    try {
      const token = Cookies.get('@tokenAvaUser');
      if (!token) {
        throw new Error('Token não encontrado. Por favor, faça login novamente.');
      }

      const decodedToken = jwtDecode<{ companyId: string }>(token);
      const companyId = decodedToken.companyId;

      if (!companyId) {
        throw new Error('ID da empresa não encontrado nos detalhes do usuário.');
      }

      const response = await apiClient.get(`vehicles?companyId=${companyId}`);
      setVehicles(response.data);
    } catch (err) {
      const errorMessage = (err as Error).message;
      setError(`Erro: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  const deleteVehicle = async (id: string) => {
    setLoading(true)

    try {
      const token = Cookies.get('@tokenAvaUser');
      if (!token) {
        throw new Error('Token não encontrado. Por favor, faça login novamente.');
      }

      const response = await apiClient.delete(`/vehicle/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setVehicles((prevVehicles) => prevVehicles.filter((vehicle) => vehicle.id !== id));
        toast.success('Veículo excluído com sucesso!');
      } else {
        throw new Error(`Falha ao excluir o veículo. Status: ${response.status}`);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(`Erro ao excluir veículo: ${error.message}`);
      } else {
        alert('Erro desconhecido ao excluir veículo. Tente novamente mais tarde.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = (id: string) => {
    navigate(`/vehicles/${id}`);
  };

  const handleEdit = (id: string) => {
    navigate(`/vehicle/updateVehicle/${id}`);
  };

  useEffect(() => {
    fetchVehicles();
  }, []);

  return {
    vehicles,
    loading,
    error,
    fetchVehicles,
    deleteVehicle,
    handleViewDetails,
    handleEdit,
  };
};
